import React from "react"
import Layout from "../components/layout"
import { container, section, subSection } from "../styles/details.module.css"

const DetailsPage = () => {
    return (
        <Layout pageTitle={"Details | Eames Maloney"}>
            <container className={container}>

                <container className={section}>
                    <h1>Location</h1>
                    <container className={subSection}>
                        <h2>Ceremony</h2>
                        <p>The Leeds Library</p>
                        <p>18 Commercial Street</p>
                        <p>Leeds</p>
                        <p>LS1 6AL</p>
                    </container>

                    <container className={subSection}>
                        <h2>Reception</h2>
                        <p>The Faversham</p>
                        <p>1-5 Springfield Mount</p>
                        <p>Leeds</p>
                        <p>LS2 9NG</p>
                    </container>
                </container>

                <container className={section}>
                    <h1>Dress Code</h1>
                    <container className={subSection}>
                        <p>Semi-formal. Ties optional.</p>
                    </container>
                </container>

                <container className={section}>
                    <h1>Parking</h1>
                    <container className={subSection}>
                        <p>The main ceremony is in the city centre so there is no parking provided by the venue. However, there is plenty of street parking near The Faversham for the reception, which is not too far from the city centre, and the university car parks are also available to use on the weekends.</p>
                    </container>
                </container>

                <container className={section}>
                    <h1>Children</h1>
                    <container className={subSection}>
                        <p>Unfortunately we are unable to accommodate children, except for babes in arms.</p>
                    </container>
                </container>

                <container className={section}>
                    <h1>Gifts</h1>
                    <container className={subSection}>
                        <p>Your presence is more than enough and the fact that you're here with us is already a gift in itself. However, if you're adamant, a contribution towards our honeymoon would be appreciated. There will be a card deposit box at the venue but if you prefer do it digitally there will be an option on the day, or speak to etiher of us.</p>
                        <p><i>n.b.</i> Niall will also accept Sleep Token tickets.</p>
                    </container>
                </container>

                <container className={section}>
                    <h1>Contact</h1>
                    <container className={subSection}>
                        <p>If you have any questions or concerns you can contact us at <a href="mailto:eames@maloney.ws">eames@maloney.ws</a>, or give either of us a call or text message.</p>
                    </container>
                </container>
            </container>
        </Layout>
    )
}

export default DetailsPage
